/**
 * TooltipComponent
 *
 */

import React, { ReactNode, useState } from 'react'

import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material'

import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import CloseIcon from '@mui/icons-material/Close'

import { makeStyles } from '@mui/styles'

interface Props {
  tooltip?: ReactNode
  label: string
}

const useStyles = makeStyles(() => ({
  tooltipIcon: {
    cursor: 'pointer',
    margin: '0 0 0 5px',
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
}))

const LabelWithTooltipComponent: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const openPopup = () => {
    setOpen(true)
  }

  const closePopup = () => {
    setOpen(false)
  }

  return (
    <div className={classes.tooltipContainer}>
      {props.label}
      {props.tooltip &&
        <IconButton onClick={openPopup}><InfoRoundedIcon color='secondary' /></IconButton>}
      <Dialog open={open} onClose={closePopup} fullWidth maxWidth='md'>
        <Stack direction='row'>
          <DialogTitle sx={{ flexGrow: 1 }}>{props.label}</DialogTitle>
          <Box sx={{ margin: 'auto', padding: 1 }}>
            <IconButton onClick={closePopup}><CloseIcon /></IconButton>
          </Box>
        </Stack>
        <DialogContent sx={{ padding: 0 }}>
          {props.tooltip}
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default LabelWithTooltipComponent
