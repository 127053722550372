export default {
  externalDatabase: false,
  entities: 0,
  columns: 0,
  foreignKeys: 0,
  searchFields: 0,
  filters: 0,
  messages: 0,
  eventHandlers: false,
  security: false,

  treeViews: 0,
  theming: false,
  languages: 0,
  fileUploadColumns: 0,
  fileUploadCloud: false,
  extendedNavigation: false,
  extendedTableView: false,
  extendedEditForms: false,
  tooltips: false,
  emptyFields: 0,
  joinEntities: 0,
  joinEntitySearch: 0,
  integratedHtmlElements: 0,
  adaptableColumns: false,
  rte: 0,
  referenceFilter: false,
  standardFilter: false,
  responsiveView: false,
}
