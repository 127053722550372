import { reducerWithInitialState } from 'typescript-fsa-reducers'
import defaultState from '../../utils/default'

import {
  setHourlyRate,
  setSettings,
} from './settings.actions'
import produce from 'immer'

export interface ISettings {
    [key:string]: number | boolean
}

export interface SettingsState {
    settings: ISettings
    hourlyRate: number
}

const INITIAL_SETTINGS_STATE: SettingsState = {
  settings: defaultState,
  hourlyRate: 150,
}

export const settingsStateReducer = reducerWithInitialState(INITIAL_SETTINGS_STATE)
  .case(setSettings, (state, object) => {
    return produce(state, (draft) => {
      if (draft.settings[object.key] === undefined) {
        console.error(`Invalid settings key '${object.key}'`)
      }
      draft.settings[object.key] = object.value
    })
  })
  .case(setHourlyRate, (state, value) => {
    return produce(state, (draft) => {
      draft.hourlyRate = value
    })
  })
