import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'

interface INumberFieldComponentProps {
  value?: number
  onChange?: (value: number) => void
  label: string
  allowNegative?: boolean
  variant?: 'int' | 'float'
}

const NumberFieldComponent: React.FC<INumberFieldComponentProps> = (props) => {
  const [value, setValue] = useState(props.value)

  const variant = props.variant ?? 'int'
  const allowNegative = props.allowNegative ?? false

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let newValue = 0
    console.log(event.target.value)
    if (event.target.value !== '') {
      newValue = (variant === 'int') ? parseInt(event.target.value) : parseFloat(event.target.value)
      if (isNaN(newValue)) {
        return
      }
      if (!allowNegative && newValue < 0) {
        return
      }
    }

    setValue(newValue)
    props.onChange && props.onChange(newValue)
  }

  return (
    <TextField variant='filled' label={props.label} type='number' value={value} onChange={handleChange} />
  )
}

export default NumberFieldComponent
