import React from 'react'
import { AppBar, Box, Container, CssBaseline, Grid, Toolbar, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import lightTheme from '../../shared/themes/LightTheme'
import ResultPanel from '../ResultPanel/ResultPanel'
import SettingsPanel from '../SettingsPanel/SettingsPanel'
import { makeStyles } from '@mui/styles'
import DeviIcon from '../../shared/Icons/DeviIcon'

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: 100,
  },
}),
)

const App: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position='fixed' color='appBar'>
          <Toolbar>
            <Box className={classes.logo}>
              <DeviIcon />
            </Box>
            <Box width={20} />
            <Typography sx={{
              fontWeight: 700,
              fontSize: 'large',
              position: 'relative',
              bottom: -10,
              color: '#a1a7b2',
              textTransform: 'uppercase',
            }}
            >{t('name', { ns: 'product' })}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ height: '100vh', flexGrow: 1 }}>
          <Toolbar />
          <Container maxWidth='xl' sx={{ padding: 3 }}>
            <Grid container spacing={3} justifyContent='center'>
              <Grid item xs={8}>
                <SettingsPanel />
              </Grid>
              <Grid item xs={3}>
                <ResultPanel />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default App
