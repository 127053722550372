import { createTheme } from '@mui/material/styles'

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#50a5f1',
    },
    secondary: {
      main: '#34c38f',
    },
    appBar: {
      main: '#2a3042',
      contrastText: '#ffffff',
    },
    background: {
      paper: '#ffffff',
      default: '#f7f7f7',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
})

export default lightTheme
