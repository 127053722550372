import React, { useState } from 'react'
import { Box, Paper, Stack, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TabCoreFeatures from './TabCoreFeatures'
import TabAdditionalFeatures from './TabAdditionalFeatures'

enum FeatureTabs {
  CoreFeatures,
  AdditionalFeatures,
}

const SettingsPanel: React.FC = () => {
  const { t } = useTranslation('settingsPanel')
  const [tab, setTab] = useState(FeatureTabs.CoreFeatures)

  return (
    <Paper>
      <Stack spacing={1}>
        <Tabs value={tab} onChange={(_, tab) => setTab(tab)}>
          <Tab value={FeatureTabs.CoreFeatures} label={t('coreFeatures')} />
          <Tab value={FeatureTabs.AdditionalFeatures} label={t('additionalFeatures')} />
        </Tabs>
        <Box sx={{ padding: 4 }}>
          <TabCoreFeatures visible={tab === FeatureTabs.CoreFeatures} />
          <TabAdditionalFeatures visible={tab === FeatureTabs.AdditionalFeatures} />
        </Box>
      </Stack>
    </Paper>
  )
}

export default SettingsPanel
