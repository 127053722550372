import React from 'react'
import { Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NumberFieldComponent from '../../shared/components/FormComponents/NumberFieldComponent'
import { useDispatch, useSelector } from 'react-redux'
import * as calculations from '../../utils/calculations'
import { SettingsRootState } from '../../store/root.reducer'
import { setHourlyRate } from '../../store/settings/settings.actions'
import { getHourlyRate } from '../../store/settings/settings.selectors'

const ResultPanel: React.FC = () => {
  const { t } = useTranslation('resultPanel')
  const dispatch = useDispatch()

  const hours = Object.values(calculations).reduce<number>(
    (hours, calculator) => (hours + useSelector<SettingsRootState, number>(
      (state) => calculator(state.settingsStore.settings),
    )),
    0,
  )

  const hourlyRate = useSelector(getHourlyRate)

  const handleChange = (value: number) => {
    dispatch(setHourlyRate(value))
  }

  return (
    <Paper sx={{ padding: 4, position: 'fixed' }}>
      <Stack alignItems='center' spacing={6}>
        <Stack spacing={1}>
          <Typography variant='body1'>{t('hourlyRate')}</Typography>
          <NumberFieldComponent value={hourlyRate} label={t('pricePerHour')} onChange={handleChange} />
        </Stack>
        <Stack alignItems='center' spacing={2}>
          <Typography variant='h6'>{t('projectedDuration')}</Typography>
          {/* TODO: Localize */}
          <Typography color='primary' variant='h3'>
            {Intl.NumberFormat(
              'de-DE',
              {
                style: 'unit',
                unit: 'hour',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              },
            ).format(hours)}
          </Typography>
        </Stack>
        <Stack alignItems='center' spacing={2}>
          <Typography variant='h6'>{t('projectedPrice')}</Typography>
          <Typography color='primary' variant='h3'>
            {Intl.NumberFormat(
              'de-DE',
              {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              },
            ).format(hours * hourlyRate)}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default ResultPanel
