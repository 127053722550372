import settingsActionCreatorFactory from 'typescript-fsa'
export const settingsActionCreator = settingsActionCreatorFactory('SETTINGS')

/**
 * Actions
 */

// calculations actions
export const setSettings = settingsActionCreator<{key: string, value: number | boolean}>('SET_SETTINGS')
export const setHourlyRate = settingsActionCreator<number>('SET_HOURLY_RATE')
