import React, { useState } from 'react'
import SelectComponent from '../../shared/components/FormComponents/SelectComponent'
import { useTranslation } from 'react-i18next'

interface IBooleanComponentProps {
  value: boolean
  onChange?: (value: boolean) => void
}

const BooleanComponent: React.FC<IBooleanComponentProps> = (props) => {
  const { t } = useTranslation('common')
  const yesOrNo = `${t('yes')}/${t('no')}`

  const [value, setValue] = useState(props.value)

  const handleChange = (v: string) => {
    setValue(v === '1')
    props.onChange && props.onChange(v === '1')
  }

  return (
    <SelectComponent
      label={yesOrNo} value={value ? '1' : '0'} onChange={handleChange} items={[
        { value: '1', label: t('yes') },
        { value: '0', label: t('no') },
      ]}
    />
  )
}

export default BooleanComponent
