import React, { ReactNode } from 'react'
import { Box, Grid, Theme, Typography } from '@mui/material'
import { setSettings } from '../../store/settings/settings.actions'
import { useSelector } from 'react-redux'
import { SettingsRootState } from '../../store/root.reducer'
import LabelWithTooltipComponent from '../../shared/components/FormComponents/LabelWithTooltipComponent'
import BooleanComponent from './BooleanComponent'
import NumberFieldComponent from '../../shared/components/FormComponents/NumberFieldComponent'
import { Dispatch } from 'redux'
import { TFunction } from 'i18next'

interface ISettingsPanelProps {
  label: ReactNode
  edit: ReactNode
}

const SettingsEntry: React.FC<ISettingsPanelProps> = (props) => (
  <Grid container alignItems='center' item xs={6}>
    <Grid item xs={7}>
      {props.label}
    </Grid>
    <Grid item xs={5}>
      {props.edit}
    </Grid>
  </Grid>
)

export const makeUtils = (dispatch: Dispatch, t: TFunction, theme: Theme) => {
  const makeProps = function <T extends (boolean | number)>(key: string): { onChange: (value: T) => void, value: T } {
    return {
      onChange: (value: T) => {
        dispatch(setSettings({ key, value }))
      },
      value: useSelector<SettingsRootState>(state => state.settingsStore.settings[key]) as T,
    }
  }
  const makeSettingsEntry = (key: string, type: 'boolean' | 'number', example?: ReactNode) => (
    <SettingsEntry
      label={<LabelWithTooltipComponent label={t(key)} tooltip={makeTooltipContent(`${key}Tooltip`, example)} />}
      edit={type === 'boolean' ? <BooleanComponent {...makeProps(key)} /> : <NumberFieldComponent label={t('value', { ns: 'common' })} {...makeProps(key)} />}
    />
  )
  const makeTooltipContent = (key: string, example?: ReactNode) => (
    <>
      <Box sx={{ padding: '0 40px 40px 40px' }}>
        <Typography variant='body2'>
          {t(key)}
        </Typography>
      </Box>
      {example &&
        <Box sx={{ backgroundColor: theme.palette.grey.A100 }}>
          <Box sx={{ padding: '40px' }}>
            <Typography variant='h6' sx={{ color: theme.palette.grey.A700 }}>{t('common:example')}</Typography><br /><br />
            <Box>
              {example}
            </Box>
          </Box>
        </Box>}
    </>
  )
  return { makeProps, makeTooltipContent, makeSettingsEntry }
}

export default SettingsEntry
