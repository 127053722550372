import React from 'react'
import {
  Box,
  Card,
  Container,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack, Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { makeUtils } from './SettingsEntry'
import { useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'

interface ITabAdditionalFeaturesProps {
  visible: boolean
}

const TabCoreFeatures: React.FC<ITabAdditionalFeaturesProps> = (props) => {
  const { t } = useTranslation('settings')
  const dispatch = useDispatch()
  const theme = useTheme()

  const { makeSettingsEntry, makeTooltipContent } = makeUtils(dispatch, t, theme)

  return (
    <Grid
      container rowSpacing={2} columnSpacing={4}
      sx={{ display: props.visible ? undefined : 'none' }}
    >
      {makeSettingsEntry('treeViews', 'number')}
      {makeSettingsEntry('extendedEditForms', 'boolean')}
      {makeSettingsEntry('theming', 'boolean')}
      {makeSettingsEntry('tooltips', 'boolean',
        <Card>
          <Stack direction='row'>
            <DialogTitle sx={{ flexGrow: 1 }}>{t('tooltips')}</DialogTitle>
            <Box sx={{ margin: 'auto', padding: 1 }}>
              <IconButton><CloseIcon /></IconButton>
            </Box>
          </Stack>
          <DialogContent>
            {makeTooltipContent('tooltipsTooltip', <Container><Typography>{t('tooltipsExample')}</Typography></Container>)}
          </DialogContent>
        </Card>)}
      {makeSettingsEntry('languages', 'number', <img src='/screenshot_languages.png' alt='' style={{ width: '100%' }} />)}
      {makeSettingsEntry('fileUploadColumns', 'number')}
      {makeSettingsEntry('joinEntities', 'number')}
      {makeSettingsEntry('fileUploadCloud', 'boolean')}
      {makeSettingsEntry('joinEntitySearch', 'number')}
      {makeSettingsEntry('extendedNavigation', 'boolean')}
      {makeSettingsEntry('extendedTableView', 'boolean')}
      {makeSettingsEntry('integratedHtmlElements', 'number')}
      {makeSettingsEntry('adaptableColumns', 'boolean')}
      {makeSettingsEntry('rte', 'number', <img src='/screenshot_rte.png' alt='' style={{ width: '100%' }} />)}
      {makeSettingsEntry('referenceFilter', 'boolean', <img src='/screenshot_reference_filters.png' alt='' style={{ width: '100%' }} />)}
      {makeSettingsEntry('standardFilter', 'boolean', <img src='/screenshot_standard_filters.png' alt='' style={{ width: '100%' }} />)}
      {makeSettingsEntry('responsiveView', 'boolean')}
    </Grid>
  )
}

export default TabCoreFeatures
