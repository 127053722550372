import React from 'react'
import { Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { makeUtils } from './SettingsEntry'
import { useDispatch } from 'react-redux'

interface ITabCoreFeaturesProps {
  visible: boolean
}

const TabCoreFeatures: React.FC<ITabCoreFeaturesProps> = (props) => {
  const { t } = useTranslation('settings')
  const dispatch = useDispatch()
  const theme = useTheme()

  const { makeSettingsEntry } = makeUtils(dispatch, t, theme)

  return (
    <Grid
      container rowSpacing={2} columnSpacing={4}
      sx={{ display: props.visible ? undefined : 'none' }}
    >
      {makeSettingsEntry('externalDatabase', 'boolean')}
      {makeSettingsEntry('searchFields', 'number')}
      {makeSettingsEntry('entities', 'number')}
      {makeSettingsEntry('messages', 'number')}
      {makeSettingsEntry('columns', 'number', <img src='/screenshot_columns.png' alt='' style={{ width: '100%' }} />)}
      {makeSettingsEntry('eventHandlers', 'boolean')}
      {makeSettingsEntry('foreignKeys', 'number',
        <div style={{ display: 'flex', gap: 10, height: 'auto' }}>
          <img src='/screenshot_reference_fields_1.png' alt='' style={{ width: '100%', height: 'fit-content' }} />
          <img src='/screenshot_reference_fields_2.png' alt='' style={{ width: '100%', height: 'fit-content' }} />
        </div>)}
      {makeSettingsEntry('security', 'boolean')}
    </Grid>
  )
}

export default TabCoreFeatures
