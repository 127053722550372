import React, { ReactNode, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

interface ISelectItemProps {
  value: string
  label: string
}

interface ISwitchComponentProps {
  value?: string
  onChange?: (value: string) => void
  label: string
  items: ISelectItemProps[]
}

const SelectComponent: React.FC<ISwitchComponentProps> = (props) => {
  // const {t} = useTranslation("common");
  //
  const [value, setValue] = useState(props.value)

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value)
    props.onChange && props.onChange(event.target.value)
  }

  return (
    <FormControl variant='filled' sx={{ width: '100%' }}>
      <InputLabel>{props.label}</InputLabel>
      <Select label={props.label} value={value} onChange={handleChange}>
        {
          props.items.map<ReactNode>((item, index) => (
            <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

export default SelectComponent
