// Only export functions that follow the type (settings: ISettings) => number.
// All calculations exported from here will be applied.

export { default as calcAdaptableColumns } from './adaptableColumns'
export { default as calcColumns } from './columns'
export { default as calcEmptyFields } from './emptyFields'
export { default as calcEntities } from './entities'
export { default as calcEventHandlers } from './eventHandlers'
export { default as calcExtendedEditForms } from './extendedEditForms'
export { default as calcExtendedNavigation } from './extendedNavigation'
export { default as calcExtendedTableView } from './extendedTableView'
export { default as calcExternalDatabase } from './externalDatabase'
export { default as calcFileUploadCloud } from './fileUploadCloud'
export { default as calcFileUploadColumns } from './fileUploadColumns'
export { default as calcFilters } from './filters'
export { default as calcForeignKeys } from './foreignKeys'
export { default as calcIntegratedHtmlElements } from './integratedHtmlElements'
export { default as calcJoinEntities } from './joinEntities'
export { default as calcJoinEntitySearch } from './joinEntitySearch'
export { default as calcLanguages } from './languages'
export { default as calcMessages } from './messages'
export { default as calcReferenceFilter } from './referenceFilter'
export { default as calcResponsiveView } from './responsiveView'
export { default as calcRte } from './rte'
export { default as calcSearchFields } from './searchFields'
export { default as calcSecurity } from './security'
export { default as calcStandardFilter } from './standardFilter'
export { default as calcTheming } from './theming'
export { default as calcTooltips } from './tooltips'
export { default as calcTreeViews } from './treeViews'
